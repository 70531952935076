.call-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90vw;
  height: 100vh;
  background: linear-gradient(135deg, #1e1e2e, #292948);
  color: white;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.listening-circle {
  width: 80vw;
  height: 80vw;
  max-width: 400px;
  max-height: 400px;
  border-radius: 50%;
  border: 8px solid rgba(255, 255, 255, 0.3);
  background: radial-gradient(circle at center, #1e1e2e, #292948); /* Default background */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.2);
  transition: all 0.5s ease, background 0.5s ease; /* Smooth background transition */
  font-size: 2.5rem;
  color: white;
  cursor: pointer;
}

.listening-circle:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.4);
}

.listening-circle.active {
  animation: glow 2s infinite alternate;
  border-color: rgba(255, 255, 255, 0.6);
}

@keyframes glow {
  0% {
    box-shadow: 0 0 10px #68d8ff, 0 0 30px #68d8ff, 0 0 50px #68d8ff;
  }
  100% {
    box-shadow: 0 0 30px #3b3bb1, 0 0 60px #3b3bb1, 0 0 100px #3b3bb1;
  }
}

.audio-controls {
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
  border-radius: 12px;
  overflow: hidden;
  background: #2e2e48;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.4);
  transition: background 0.3s ease;
}

.audio-controls:hover {
  background: #393959;
}

.audio-controls::-webkit-media-controls-panel {
  background-color: #292948;
  color: #ffffff;
  border-radius: 12px;
}

.audio-controls::-webkit-media-controls-play-button,
.audio-controls::-webkit-media-controls-mute-button {
  filter: invert(100%);
}

.audio-controls::-webkit-media-controls-current-time-display,
.audio-controls::-webkit-media-controls-time-remaining-display {
  color: #fff;
}

.audio-controls::-webkit-media-controls-timeline {
  height: 3px; /* Thinner line */
  background-color: rgba(255, 255, 255, 0); /* Subtle, semi-transparent */
}

.audio-controls::-webkit-media-controls-volume-slider {
  background-color: #ff5959;
  transition: background 0.3s ease;
}

audio::-webkit-media-controls-enclosure {
  border-radius: 12px;
  overflow: hidden;
}

audio:hover::-webkit-media-controls-volume-slider {
  background-color: #ff7373;
}
