/* Background and container settings */
body {
    background-color: #2c2f33;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
  }
  
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .login-box {
    background-color: #23272a;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 320px;
  }
  
  /* Align the logo to center */
.app-logo {
    display: block; /* Make sure the image behaves as a block element */
    margin-left: auto; /* Push the logo from the left */
    margin-right: auto; /* Push the logo from the right */
    margin-bottom: 20px; /* Add space between the logo and the form fields */
    width: 100px; /* Adjust the width of the logo as needed */
  }
  
  .login-title {
    color: #fff;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .login-form .input-container {
    margin-bottom: 15px;
  }
  
  label {
    color: #ddd;
    font-size: 14px;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #2c2f33;
    color: #fff;
  }
  
  .input-field:focus {
    outline: none;
    border: 1px solid #7289da;
  }
  
  .login-btn {
    width: 100%;
    padding: 10px;
    background-color: #7289da;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .login-btn:hover {
    background-color: #5b6eae;
  }
  
  .signup-link {
    text-align: center;
    margin-top: 15px;
  }
  
  .signup-link p {
    color: #ccc;
  }
  
  .signup-link a {
    color: #7289da;
    text-decoration: none;
  }
  
  .signup-link a:hover {
    text-decoration: underline;
  }
  