.emergency-contacts-container {
    padding: 20px;
    color: white;
    background-color: #1e1e2e;
    border-radius: 10px;
    width: 80%;
    margin: 0 auto;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    /* width: 20vw; */
    height: 100vh;
  }
  
  h1 {
    text-align: center;
    color: #6d48f5;
  }
  
  .contacts-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .contact-card {
    background-color: #292948;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .contact-card h2 {
    color: #68d8ff;
  }
  
  .contact-card p {
    color: #ffffff;
  }
  
  .contact-card strong {
    color: #ff5959;
  }
  
  .contact-card a {
    color: #68d8ff;
    text-decoration: none;
    white-space: nowrap; /* Prevent line breaks for phone numbers */
  }
  
  .contact-card a:hover {
    color: #ff5959;
    text-decoration: underline;
  }
  